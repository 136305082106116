/* global define */

define('js/data/data-source',['require','js/utilities/repository','js/utilities/render'],function (require) {
	'use strict';

	var repository = require('js/utilities/repository');
	var render = require('js/utilities/render');
	var DataSource = function () {};
	var ds = new DataSource();

	DataSource.prototype.getColumns = function () {
		return [
			{
				label: render('{{CP_JB_SUMMARY_LABEL_FORM_NAME}}'),
				property: 'name',
				sortable: true
			},
			{
				label: render('{{CP_JB_EVENT_LABEL_PAGE_NAME}}'),
				property: 'pageName',
				sortable: true
			},
			{
				label: render('{{CP_JB_EVENT_LABEL_CONTENT_TYPE}}'),
				property: 'contentType',
				sortable: true
			},
			{
				label: render('{{CP_JB_EVENT_LABEL_FOLDER}}'),
				property: 'collectionName',
				sortable: true
			}
		];
	}

	/**
	 * Gets the payload to return to the callback method for the FuelUX 3 Repeater
	 * @param {Object} formsData
	 * @param {Object} options
	 * @returns {Object}
	 */
	DataSource.prototype.getCallbackPayload = function (formsData, options) {
		formsData = formsData || {};

		var items = formsData.entities || [];
		var page = formsData.page || 1;
		var pageIndex = options.pageIndex || (page - 1);
		var pageSize = options.pageSize || formsData.pageSize || 10;
		var count = formsData.totalCount || 0;
		var pages = Math.ceil(count / pageSize);
		var startIndex = pageIndex * pageSize;
		var start = startIndex + 1;
		var end = Math.min(startIndex + pageSize, count);

		items = items.map(function (item) {
			// The contentType comes from the 'getForms' function needs to be localized.
			item.contentType = render(item.contentType);
			item.publishedStatus = render(item.publishedStatus);
			return item;
		});

		return {
			page: pageIndex,
			pages: pages,
			count: count,
			start: start,
			end: end,
			columns: ds.getColumns(),
			items: items
		};
	}

	
	DataSource.prototype.datasource = function(options, callback) {
		repository.getForms({ queryParams: options })
		.then(function (formsData) {
			callback(ds.getCallbackPayload(formsData, options));
		});
	};

	return ds;
});

