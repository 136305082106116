/* global define */

define('js/utilities/channel',['require','backbone.radio'],function (require) {
	'use strict';

	var Radio = require('backbone.radio');
	var channel = new Radio.Channel();

	/**
	 * Supported channel methods: request, reply, on, off, trigger
	 */
	return channel;
});

