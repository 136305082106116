/* global define */

define('js/utilities/dictionary',['require','js/utilities/repository','js/config/i18n-fallbacks','underscore','core-js'],function (require) {
	'use strict';

	var repository = require('js/utilities/repository');
	var fallbackDictionary = require('js/config/i18n-fallbacks');
	var _ = require('underscore');

	// require polyfill for promise support
	require('core-js');

	return {
		loadDictionary: function () {
			var self = this;

			return repository.getDictionary()
			.then(function (dictionary) {
				self.dictionary = _.extend({}, fallbackDictionary, dictionary);
			});
		},

		getDictionary: function () {
			return this.dictionary;
		}
	};
});

