/* global define */

define('js/utilities/render',['require','backbone.marionette','js/utilities/dictionary','underscore','handlebars'],function (require) {
	'use strict';

	var Mn = require('backbone.marionette');
	var dictionary = require('js/utilities/dictionary');
	var _ = require('underscore');
	var handlebars = require('handlebars');

	// override the renderer method to use our dictionary
	return Mn.Renderer.render = function (template, data) {
		data = _.extend({}, dictionary.getDictionary(), data);

		if (!template) {
			return '';
		}

		if (_.isString(template)) {
			template = handlebars.compile(template);
		}

		var templateFunc = _.isFunction(template) ? template : Mn.TemplateCache.get(template);

		return templateFunc(data);
	};

});

