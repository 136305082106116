/* global define, Promise */

define('js/utilities/repository',['require','js/utilities/token-handler','js/constants','jquery','underscore','postmonger','core-js'],function (require) {
	'use strict';

	var tokenHandler = require('js/utilities/token-handler');
	var constants = require('js/constants');
	var $ = require('jquery');
	var _ = require('underscore');
	var Postmonger = require('postmonger');
	var connection = new Postmonger.Session();
	var Repository;
	var ContentTypeMap = {
		'FacebookTab': '{{CP_JB_EVENT_CHANNEL_FACEBOOK_TAB}}',
		'MobilePushPage': '{{CP_JB_EVENT_CHANNEL_MOBILEPUSH_PAGE}}',
		'LandingPage': '{{CP_JB_EVENT_CHANNEL_LANDING_PAGE}}',
		'Microsite': '{{CP_JB_EVENT_CHANNEL_MICROSITE}}'
	};
	var PublishedStatusMap = {
		'Unpublished': '{{CP_JB_EVENT_STATUS_UNPUBLISHED}}',
		'Published': '{{CP_JB_EVENT_STATUS_LIVE}}',
		'Draft': '{{CP_JB_EVENT_STATUS_DRAFT}}',
		'Archived': '{{CP_JB_EVENT_STATUS_ARCHIVED}}',
		'Scheduled': '{{CP_JB_EVENT_STATUS_SCHEDULED}}'
	};
	var fuelApiHost;

	// require polyfill for promise support
	require('core-js');

	function getFuelApiHost() {
		return new Promise(function (resolve, reject) {
			if (!fuelApiHost) {
				connection.on('requestedEndpoints', function (endpoints) {
					fuelApiHost = endpoints.fuelapiRestHost || void 0;
					connection.off(null, null);

					if (!fuelApiHost) {
						reject('No tokens provided');
					} else {
						resolve(fuelApiHost);
					}
				});

				connection.trigger('requestEndpoints');
			} else {
				resolve(fuelApiHost);
			}
		});
	}

	function fetch(options) {
		if (!options || !options.url) {
			throw new Error('Missing required parameters');
		}

		return Promise.resolve()
		.then(function () {
			return options.tokenRefresh ? tokenHandler.refreshToken() : tokenHandler.getToken();
		})
		.then(function (tokens) {
			return getFuelApiHost().then(function (fuelApiRestHost) {
				return $.ajax({
					url: options.url,
					contentType: 'application/json',
					headers: {
						authorization: 'Bearer ' + tokens.fuel2token,
						'x-fuel-api-rest-host': fuelApiRestHost
					}
				});
			});
		});
	}

	function fetchWithAuth(options) {
		return new Promise(function (resolve, reject) {
			// First fetch with existing credentials
			fetch(options)
			.then(function (data) {
				// Success!
				resolve(JSON.parse(data));
			})
			.catch(function (data) {
				if (data && data.status === 401) {
					// Second fetch with new credentials
					fetch(_.extend({}, options, { tokenRefresh: true }))
					.then(function (data) {
						// Success
						resolve(JSON.parse(data));
					})
					.catch(function () {
						// Still getting an error
						reject(new Error());
					});
				}
				else {
					// Getting an error other than a 401
					reject(new Error());
				}
			});
		});
	}

	Repository = function () {
		getFuelApiHost().then(function (fuelApiRestHost) {
			fuelApiHost = fuelApiRestHost;
		});
	};

	Repository.prototype.getForms = function (options) {
		options = options || {};
		var urlParams = ['dataTargetTypeId=1', 'isUsedInInteraction=false'];
		var channelTypeID;
		var queryParams = options.queryParams;
		var sortProperty;

		if (queryParams) {
			if (queryParams.pageIndex >= 0) {
				urlParams.push('$page=' + (queryParams.pageIndex + 1));
			}
			if (queryParams.pageSize > 0) {
				urlParams.push('$pageSize=' + queryParams.pageSize);
			}
			channelTypeID = queryParams.filter ? parseInt(queryParams.filter.value, 10) : 0;
			if (channelTypeID && !isNaN(channelTypeID)) {
				urlParams.push('channelTypeId=' + channelTypeID);
			}
			sortProperty = queryParams.sortProperty;
			if (sortProperty) {
				if (sortProperty === 'contentType') {
					sortProperty = 'channelTypeId';
				}
				urlParams.push('$orderBy=' + encodeURIComponent(sortProperty + (queryParams.sortDirection === 'desc' ? ' desc' : '')));
			}
			if (queryParams.search) {
				urlParams.push('name=contains(' + encodeURIComponent(queryParams.search) + ')');
			}
		}

		return fetchWithAuth({
			url: '/fuelapi/internal/v2/CloudPagesJourneyBuilderEvent/smartcapture-forms?' + urlParams.join('&')
		})
		.then(this.parseFormData)
		.catch(function () {
			// error fetching
		});
	};

	Repository.prototype.parseFormData = function (formData) {
		// Parse
		if (formData && formData.entities) {
			formData.entities.map(function(thisFormData) {
				thisFormData.fields = thisFormData.fields || [];
				thisFormData.contentType = ContentTypeMap[thisFormData.channelType] || '';
				thisFormData.publishedStatus = PublishedStatusMap[thisFormData.pageStatus] || '';

				return thisFormData;
			});
			return formData;
		} else {
			return {};
		}
	};

	Repository.prototype.getDictionary = function () {
		return fetchWithAuth({
			url: '/fuelapi/platform-internal/v1/applications/' + constants.localizationAppID + '/resourceSets/key:' + constants.localizationResourceSet
		})
		.then(function (dictionary) {
			// Parse
			try {
				return JSON.parse(dictionary.items);
			} catch (e) {
				return {};
			}
		})
		.catch(function () {
			//error fetching
		});
	};

	return new Repository();
});

