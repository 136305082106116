
/* START_TEMPLATE */
define('hbs!js/views/root',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div data-view=\"alerts\"></div>\n<div class=\"header\">\n	<h1>"
    + this.escapeExpression(((helper = (helper = helpers.CP_JB_CHOOSE_SMART_CAPTURE_FORM || (depth0 != null ? depth0.CP_JB_CHOOSE_SMART_CAPTURE_FORM : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"CP_JB_CHOOSE_SMART_CAPTURE_FORM","hash":{},"data":data}) : helper)))
    + "</h1>\n	<p>"
    + this.escapeExpression(((helper = (helper = helpers.CP_JB_HEADLINE_HELP_BLOCK || (depth0 != null ? depth0.CP_JB_HEADLINE_HELP_BLOCK : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"CP_JB_HEADLINE_HELP_BLOCK","hash":{},"data":data}) : helper)))
    + "</p>\n</div>\n<div class=\"body\">\n	<div data-view=\"form-selector\"></div>\n	<div data-view=\"form-details\"></div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('js/views/root', t);
return t;
});
/* END_TEMPLATE */
;
