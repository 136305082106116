/* global define */

define('js/app',['require','js/views/root','js/utilities/channel','js/utilities/dictionary','postmonger','backbone.marionette','jquery','underscore','fuelux','js/event','js/utilities/token-handler','js/utilities/render'],function (require) {
	'use strict';

	var RootView = require('js/views/root');
	var channel = require('js/utilities/channel');
	var dictionary = require('js/utilities/dictionary');
	var Postmonger = require('postmonger');
	var Mn = require('backbone.marionette');
	var $ = require('jquery');
	var _ = require('underscore');

	var connection = new Postmonger.Session();
	var App;
	var application;

	require('fuelux');
	require('js/event');
	require('js/utilities/token-handler');
	require('js/utilities/render');

	App =  Mn.Application.extend({
		formData: void 0,
		payload: void 0,

		onStart: function () {
			var self = this;

			/**
			 * Since the '_onReady' 'this' context is different from the '_initChannelBus', 'this.payload' would be
			 * 'undefined', unless the '_onReady' 'this' context was the same as the '_getPayload' 'this' context.
			 */
			_.bindAll(this, '_onReady', '_getPayload');
			this._initChannelBus();
			this.rootView = new RootView({
				el: $('[data-view="application"]')
			});
			dictionary.loadDictionary()
			.then(function () {
				self.rootView.render();
			})
			.catch(function () {
				self.rootView.render();
			});

			connection.on('initEvent', this._onReady);
			connection.trigger('ready'); // JB will respond the first time 'ready' is called with 'initEvent'
		},

		_initChannelBus: function () {
			channel.reply({
				formData: this._getFormData,
				payload: this._getPayload
			});

			channel.on({
				setFormData: this._setFormData
			});
		},

		_getFormData: function () {
			return this.formData;
		},

		_getPayload: function () {
			return this.payload;
		},

		_onReady: function (data) {
			this.payload = data || this.payload;
		},

		_setFormData: function (data) {
			this.formData = data;
		}
	});

	application = new App();
	application.start();
});

