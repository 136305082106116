/* global define, Promise */

define('js/utilities/token-handler',['require','postmonger','core-js'],function (require) {
	'use strict';

	var Postmonger = require('postmonger');
	var connection = new Postmonger.Session();

	// require polyfill for promise support
	require('core-js');

	function TokenHandler() {
		this._token = void 0;
		this._fuel2token = void 0;
	}

	TokenHandler.prototype.getToken = function () {
		return {
			token: this._token,
			fuel2token: this._fuel2token
		};
	};

	TokenHandler.prototype.refreshToken = function () {
		var self = this;

		return new Promise(function (resolve, reject) {
			connection.on('requestedTokens', function (data) {
				this._token = data.token || void 0;
				this._fuel2token = data.fuel2token || void 0;
				connection.off(null, null, self);

				if (!this._token && !this._fuel2token) {
					reject('No tokens provided');
				} else {
					resolve(self.getToken());
				}
			}, self);

			connection.trigger('requestTokens');
		});
	};

	return new TokenHandler();
});

