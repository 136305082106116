/* global define */

define('js/views/alert',['require','backbone','backbone.marionette','hbs!js/views/alert'],function (require) {
	'use strict';

	var Backbone = require('backbone');
	var Mn = require('backbone.marionette');
	var template = require('hbs!js/views/alert');

	return Mn.ItemView.extend({
		template: template,

		initialize: function (options) {
			this.model = new Backbone.Model(options);
		}
	});
});

