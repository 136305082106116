/* global define */

define('js/views/form-details',['require','backbone','backbone.marionette','hbs!js/views/form-details','js/utilities/channel'],function (require) {
	'use strict';

	var Backbone = require('backbone');
	var Mn = require('backbone.marionette');
	var template = require('hbs!js/views/form-details');
	var channel = require('js/utilities/channel');

	return Mn.ItemView.extend({
		template: template,

		modelEvents: {
			'change': 'render'
		},

		constructor: function () {
			this.model = new Backbone.Model();

			Mn.ItemView.prototype.constructor.apply(this, arguments);
		},

		initialize: function () {
			this.listenTo(channel, 'updateFormDetails', this._setFormFieldData);
		},

		_setFormFieldData: function () {
			var formData = channel.request('formData');

			this.model.clear().set(formData, {reset: true});
		}
	});
});

