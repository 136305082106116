/* global define */

define('js/views/form-selector',['require','backbone.marionette','hbs!js/views/form-selector','js/data/data-source','js/utilities/actions','jquery'],function (require) {
	'use strict';

	var Mn = require('backbone.marionette');
	var template = require('hbs!js/views/form-selector');
	var dataSource = require('js/data/data-source');
	var actions = require('js/utilities/actions');
	var $ = require('jquery');

	return Mn.ItemView.extend({
		template: template,

		ui: {
			$repeater: '.repeater'
		},

		attributes: {
			style: 'height: 100%;'
		},

		onShow: function () {
			this._initRepeater();
		},

		_initRepeater: function () {
			var self = this;

			this.ui.$repeater.repeater({
				dataSource: dataSource.datasource,
				list_columnRendered: self._customColumnRenderer,
				staticHeight: true,
				defaultView: 'list',
				views: {
					'list': {
						list_selectable: true
					}
				}
			});
		},

		_customColumnRenderer: function (helpers, callback) {
			var column = helpers.columnAttr;

			var rowData = helpers.rowData;
			var customMarkup = '';

			switch(column) {
				case 'name':
					// combine name and publishedStatus into a single column
					customMarkup = rowData.name + '<br>' + rowData.publishedStatus;
					break;

				case 'pageName':
					// combine pageName and subPageName (Microsite Name)
					if (rowData.contentType === "Microsite") {
						var micrositeName = rowData.subPageName || '';
						customMarkup = rowData.pageName + '<br>' + micrositeName;
					} else {
						customMarkup = helpers.item.text();
					}
					break;

				default:
					// otherwise, just use the existing text value
					customMarkup = helpers.item.text();
					break;
			}

			helpers.item.html(customMarkup);

			return callback();
		},

		events: {
			'selected.fu.repeaterList': '_showAttributes',
			'deselected.fu.repeaterList': '_clearAttributes'
		},

		_clearAttributes: function () {
			actions.selectForm();
		},

		_showAttributes: function(e, el) {
			var data = $(el).data();
			var itemData = data.item_data;
			var formID = data.item_data.smartCaptureFormId;

			if (formID) {
				actions.selectForm(itemData);
			}
		}
	});
});

