/* global define */

define('js/utilities/actions',['require','js/utilities/channel'],function (require) {
	'use strict';

	var channel = require('js/utilities/channel');

	// Methods in the action module should be action-based instead of logic-based
	// example: "selectForm" instead of "setFormData" since the view calling the Methods
	// should not be aware of the logic being performed in this module
	return {
		selectForm: function (options) {
			channel.trigger('setFormData', options);
			channel.trigger('updateFormDetails');
		}
	};
});

