
/* START_TEMPLATE */
define('hbs!js/views/form-details',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<div class=\"data-target-heading\">"
    + this.escapeExpression(((helper = (helper = helpers.CP_JB_SUMMARY_LABEL_DATA_TARGET || (depth0 != null ? depth0.CP_JB_SUMMARY_LABEL_DATA_TARGET : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"CP_JB_SUMMARY_LABEL_DATA_TARGET","hash":{},"data":data}) : helper)))
    + "</div>\n		<span data-view=\"data-target\">"
    + this.escapeExpression(((helper = (helper = helpers.dataTargetType || (depth0 != null ? depth0.dataTargetType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"dataTargetType","hash":{},"data":data}) : helper)))
    + " "
    + this.escapeExpression(((helper = (helper = helpers.dataTargetName || (depth0 != null ? depth0.dataTargetName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"dataTargetName","hash":{},"data":data}) : helper)))
    + "</span>\n		<ul class=\"form-attributes-list\" data-view=\"data-attributes\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.fields : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</ul>\n";
},"2":function(depth0,helpers,partials,data) {
    var helper;

  return "				<li>"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</li>\n";
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "		<div class=\"data-target-empty\">"
    + this.escapeExpression(((helper = (helper = helpers.CP_JB_ATTRIBUTES_EMPTY || (depth0 != null ? depth0.CP_JB_ATTRIBUTES_EMPTY : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"CP_JB_ATTRIBUTES_EMPTY","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"cp-container repeater\">\n	<div class=\"cp-container-header repeater-header\">"
    + this.escapeExpression(((helper = (helper = helpers.CP_JB_EVENT_LABEL_ATTRIBUTES || (depth0 != null ? depth0.CP_JB_EVENT_LABEL_ATTRIBUTES : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"CP_JB_EVENT_LABEL_ATTRIBUTES","hash":{},"data":data}) : helper)))
    + "</div>\n	<div class=\"cp-container-body form-attributes\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.fields : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.fields : depth0),{"name":"unless","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('js/views/form-details', t);
return t;
});
/* END_TEMPLATE */
;
