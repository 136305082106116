requirejs.config({ // eslint-disable-line no-undef
	baseUrl: '.',
	deps: [ 'js/app' ],
	paths: {
		// VENDOR
		'backbone': '../bower_components/backbone/backbone',
		'backbone.marionette': '../bower_components/backbone.marionette/lib/backbone.marionette',
		'backbone.radio': '../bower_components/backbone.radio/build/backbone.radio',
		'bootstrap': '../bower_components/bootstrap/dist/js/bootstrap',
		'core-js': '../bower_components/core.js/client/core',
		'fuelux': '../bower_components/fuelux/dist/js/fuelux',
		'handlebars': '../bower_components/handlebars/handlebars',
		'hbs': '../bower_components/require-handlebars-plugin/hbs',
		'jquery': '../bower_components/jquery/dist/jquery',
		'jquery-ajaxtransport-xdomainrequest': '../bower_components/jQuery-ajaxTransport-XDomainRequest/jQuery.XDomainRequest',
		'moment': '../bower_components/moment/moment',
		'postmonger': '../bower_components/postmonger/postmonger',
		'text': '../bower_components/text/text',
		'underscore': '../bower_components/underscore/underscore'
	},
	shim: {
		'fuelux': {
			deps: ['moment']
		}
	}
});

define("js/main", function(){});

