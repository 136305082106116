/* global define */

define('js/views/root',['require','js/views/form-details','js/views/form-selector','js/views/alert','js/utilities/channel','backbone.marionette','hbs!js/views/root'],function (require) {
	'use strict';

	var FormDetails = require('js/views/form-details');
	var FormSelector = require('js/views/form-selector');
	var Alerts = require('js/views/alert');
	var channel = require('js/utilities/channel');
	var Mn = require('backbone.marionette');
	var template = require('hbs!js/views/root');

	return Mn.LayoutView.extend({
		template: template,

		regions: {
			formSelector: '[data-view="form-selector"]',
			formDetails: '[data-view="form-details"]',
			alerts: '[data-view="alerts"]'
		},

		views: void 0,

		initialize: function () {
			this.listenTo(channel, 'show-alert', this._initAlerts);
		},

		onRender: function () {
			this.views = this.views || {};

			this._initFormSelector();
			this._initFormDetails();
		},

		_initAlerts: function (options) {
			var alertsView = this.views.alerts = new Alerts(options);

			this.alerts.show(alertsView);
		},

		_initFormDetails: function () {
			var formDetailsView = this.views.formDetails;

			if (!formDetailsView) {
				formDetailsView = this.views.formDetails = new FormDetails();
			}

			this.formDetails.show(formDetailsView);
		},

		_initFormSelector: function () {
			var formSelectorView = this.views.formSelector;

			if (!formSelectorView) {
				formSelectorView = this.views.formSelector = new FormSelector();
			}

			this.formSelector.show(formSelectorView);
		}
	});
});

