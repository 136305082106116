/* global define */

define('js/config/i18n-fallbacks',['require'],function (require) {
	'use strict';

	var fallbacks = {
		"CP_JB_CATEGORY_NAME": "CloudPages",
		"CP_JB_EVENT_NAME": "CloudPages Form Submit Event",
		"CP_JB_EVENT_DESCRIPTION": "Detects form submission from a specific CloudPages Smart Capture form.",
		"CP_JB_EVENT_STEP_SELECT_SOURCE": "Select Event Source",
		"CP_JB_CHOOSE_SMART_CAPTURE_FORM": "Choose a Smart Capture form.",
		"CP_JB_HEADLINE_HELP_BLOCK": "Forms shown here use a compatible Data Target and are named in CloudPages.",
		"CP_JB_ALERT_NO_FORM_SELECTED": "Select a form to continue.",
		"CP_JB_SUMMARY_LABEL_FORM_NAME": "Smart Capture Form",
		"CP_JB_SUMMARY_LABEL_DATA_TARGET": "Data Target",
		"CP_JB_SUMMARY_LABEL_ATTRIBUTES": "Attributes",
		"CP_JB_ATTRIBUTES_EMPTY": "Click a row to view the attributes its Smart Capture form contains.",
		"VIEW_BY_CONTENT_TYPE": "View by Content Type",
		"CP_JB_EVENT_LABEL_PAGE_NAME": "Page Name",
		"CP_JB_EVENT_LABEL_CONTENT_TYPE": "Content Type",
		"CP_JB_EVENT_FILTER_ALL": "All",
		"CP_JB_EVENT_CHANNEL_LANDING_PAGE": "Landing Page",
		"CP_JB_EVENT_CHANNEL_FACEBOOK_TAB": "Facebook Tab",
		"CP_JB_EVENT_CHANNEL_MOBILEPUSH_PAGE": "MobilePush Page",
		"CP_JB_EVENT_CHANNEL_MICROSITE": "Microsite",
		"CP_JB_EVENT_LABEL_FOLDER": "Folder",
		"CP_JB_EVENT_LABEL_ATTRIBUTES": "Attributes",
		"CP_JB_EVENT_STATUS_UNPUBLISHED": "Unpublished",
		"CP_JB_EVENT_STATUS_LIVE": "Live",
		"CP_JB_EVENT_STATUS_DRAFT": "Draft",
		"CP_JB_EVENT_STATUS_ARCHIVED": "Archived",
		"CP_JB_EVENT_STATUS_SCHEDULED": "Scheduled"
	};

	return fallbacks;
});

