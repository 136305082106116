/* global define */

define('js/event',['require','postmonger','js/utilities/channel','js/utilities/render'],function (require) {
	'use strict';

	var Postmonger = require('postmonger');
	var channel = require('js/utilities/channel');
	var render = require('js/utilities/render');

	var connection = new Postmonger.Session();

	connection.on('clickedNext', onClickedNext);
	connection.on('clickedBack', onClickedBack);
	connection.on('gotoStep', onGotoStep);

	function onClickedNext () {
		var formData = channel.request('formData') || {};

		if (!formData.dataTargetId) {
			channel.trigger('show-alert', {
				type: 'danger',
				message: render('{{CP_JB_ALERT_NO_FORM_SELECTED}}')
			});

			connection.trigger('ready');
		} else {
			save();
		}
	}

	function onClickedBack () {
		connection.trigger('prevStep');
	}

	function onGotoStep () {
		connection.trigger('ready');
	}

	function save() {
		var formData = channel.request('formData');
		var payload = channel.request('payload') || {};

		payload['arguments'] = payload['arguments'] || {};
		payload['arguments'].dataTargetName = formData.dataTargetName;
		payload['arguments'].formName = formData.name;
		// payload['arguments'].criteria = "<FilterDefinition Source='SubscriberAttribute'><ConditionSet Operator='AND' ConditionSetName='Grouping'><Condition ID='13D65BB5-1F98-E411-9D68-00237D5401CE' isParam='false' Operator='Equal' operatorEditable='0' valueEditable='1' annotation=''><Value><![CDATA[" + formID + "]]></Value></Condition><Condition ID='0CD65BB5-1F98-E411-9D68-00237D5401CE' isParam='false' Operator='Equal' operatorEditable='0' valueEditable='1' annotation=''><Value><![CDATA[" + firstName + "]]></Value></Condition><Condition ID='12D65BB5-1F98-E411-9D68-00237D5401CE' isParam='false' Operator='Equal' operatorEditable='0' valueEditable='1' annotation=''><Value><![CDATA[" + lastName + "]]></Value></Condition></ConditionSet></FilterDefinition>";

		payload.metaData = payload.metaData || {};
		payload.configurationArguments = payload.configurationArguments || {};
		payload.configurationArguments.smartCaptureFormID = formData.smartCaptureFormId;

		switch (formData.dataTargetTypeId) {
			case 1:
				payload.dataExtensionId = formData.dataTargetId; // Pass either schema OR data extension ID
				break;
			case 2:
				payload.schema = {
					"fields": [
						{
							"name": "formID",
							"dataType": "number",
							"maxLength": "",
							"isNullable": false,
							"defaultValue": ""
						}
					],
					"sendableCustomObjectField": "EmailAddress",
					"sendableSubscriberField": "EmailAddress"
				};
				break;
			default:
				break;
		}

		connection.trigger('updateEvent', payload);
	}
});

