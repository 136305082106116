/* global define */

define('js/constants',[],function () {
	'use strict';

	return {
		localizationAppID: '9744cdb1-92d0-44fa-a1bf-201d29fafd1e',
		localizationResourceSet: 'CloudPages.SmartCaptureEvent'
	};
});

